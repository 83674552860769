import React from 'react';
import './App.css';
import daiLogo from './assets/dai-logo.svg';

function Header() {
  return (
    <header className='header gutter'>
      <div className='centered-content'>
        <img className='logo' src={daiLogo} alt="Logo, De Afdeling Innovatie"/>
        <h1 className='header-title'>Wij zijn DAI, <br/>de afdeling innovatie<br/> voor jouw bedrijf.</h1>
        <p className='header-intro'>
          Wij zijn de tijdelijke innovatieafdeling voor bedrijven die verandering omarmen en een positieve impact willen maken. 
          Samen ontwikkelen we nieuwe perspectieven op bestaande <span className='impact'>bedrijfsvoering,</span> <span className='impact'>duurzaamheid,</span> nieuwe <span className='impact'>producten en diensten</span> of <span className='impact'>het realiseren van het idee</span> dat tot nu toe alleen in je gedachten of op papier bestaat. 
          Wij zetten deze nieuwe perspectieven om in concrete ontwerpen en een succesvolle realisatie.</p>

      </div>
      
    </header>
  );
}

export default Header;