import React from 'react';
import './App.css';

function ContactItem({name, email, phone}) {
  return (
    <article>
      <h3 class="contact-item-title">{name}</h3>
      <p class="contact-item-text">{email}<br/>{phone}</p>
    </article>
  );
}

export default ContactItem;