import React from 'react';
import './App.css';

function Quote() {
  return (
    <div className='gutter '>
      <article className='bubble centered-content'>
        <h2 className='quote-title'>Is innovatie alleen voorbehouden aan grote organisaties met een eigen afdeling? Wij denken van niet.</h2>
        <p className='quote-text'>Wij zijn de tijdelijke innovatieafdeling voor het MKB, familiebedrijven en maatschappelijke organisaties. Samen transformeren we kansen en uitdagingen in concrete innovaties, slimme concepten en waardevolle diensten.</p>
      </article>
    </div>
  );
}

export default Quote;