import React from 'react';
import './App.css';
import landmakersBg from './assets/Landmakers-bg.png';
import landmakersSm from './assets/Landmakers-sm.png';

function CaseLandmakers() {
  return (
    <article className='case-landmakers gutter centered-content'>

        <div className='case-landmakers-images'>
            <img className='case-img-tall' src={landmakersBg} aria-hidden="true" alt="Landmakers, een nieuw perspectief op biodiversiteit"/>
            <img className='case-img-small-mobile' src={landmakersSm} aria-hidden="true" alt="Landmakers, een nieuw perspectief op biodiversiteit"/>
        </div>
        
        <aside>
            <h1 id="cases" className='case-title'>Landmakers</h1>
            <h2 className='case-title-big'>Een nieuw perspectief op <span className='impact-title'>biodiversiteit</span></h2>
            <p className='case-intro'>	
                Ons landschap vraagt om een gezamenlijke aanpak voor een vitaal en gezond ecosysteem. Landmakers ondersteund grondeigenaren om meer uit het landschap te halen en biodiversiteit te stimuleren. Landmakers is een eigen initiatief, gerealiseerd in samenwerking met Trees for All en Landschap Overijssel. 
            </p>

            <img className='case-img-small' src={landmakersSm} aria-hidden="true" alt="Landmakers, een nieuw perspectief op biodiversiteit"/>
        </aside>

    </article>
  );
}

export default CaseLandmakers;