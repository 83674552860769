import React from 'react';
import Header from './Header';
import Contact from './Contact';
import CaseLandmakers from './CaseLandmakers';
import Quote from './Quote';
import CaseItemOb from './CaseItemOb';
import CaseItemAs from './CaseItemAs';
import ArrowDown from './assets/arrow-down.svg';


function App() {
  const handleScroll = (e) => {
    e.preventDefault();
    const element = document.querySelector('#cases');
    window.scrollTo({
      behavior: 'smooth',
      top: element.offsetTop
    });
  };

  return (
    <div>
      <Header />
      <div className='contact-background'>
        <div className='gutter-left-right centered-content'>
          <a className='arrow-down-wrapper' href='#cases' onClick={handleScroll}>
            <img className='arrow-down' src={ArrowDown} aria-hidden="true" alt="arrow down"/>
          </a>
        </div>
      </div>
      <CaseLandmakers />
      <Quote />
      <CaseItemOb />
      <CaseItemAs />
      <Contact />

      
      <div className='gutter centered-content'>
        <p><span className='impact impact-sm'>De Afdeling Innovatie</span> <br/>KVK: 94561729</p>
      </div>

    </div>
  );
}

export default App;