import React from 'react';
import OmgekeerdBouwen from './assets/Omgekeerd-bouwen.png';
import './App.css';

function CaseItemOb() {
  return (
    <article className='case-item-ob case-item gutter centered-content'>
        <aside>
            <h1 className='case-title'>Omgekeerd Bouwen </h1>
            <h2 className='case-title-big'>Een nieuw perspectief op <span className='impact-title'>grondstoffen</span></h2>
            <p className='case-intro'>	
            In plaats van een gebouw volledig te slopen, onderzoekt <a  href="https://www.kamphuissloopwerken.nl/omgekeerd-bouwen" target="_blank" rel="noopener noreferrer">Omgekeerd Bouwen</a> welke materialen hergebruikt kunnen worden, waardoor verspilling wordt geminimaliseerd. De vrijgekomen materialen krijgen een tweede leven via zowel een fysieke als een online marktplaats. Omgekeerd Bouwen is een initiatief Kamphuis Sloopwerken en vormt inmiddels een integraal onderdeel van de werkwijze. Wij ondersteunden in het ontwerp en realisatie van dit concept. 
            </p>

        </aside>
        <img className='case-img' src={OmgekeerdBouwen} aria-hidden="true" alt="Omgegekeerd bouwen"/>
    </article>
  );
}

export default CaseItemOb;