import React from 'react';
import Asito from './assets/Asito.png';
import './App.css';

function CaseItemAs() {
  return (
      <article className='case-item-as case-item gutter centered-content'>
        <img className='case-img' src={Asito} aria-hidden="true" alt="Asito"/>
        <aside>
            <h1 className='case-title'>Asito</h1>
            <h2 className='case-title-big'>Een nieuw perspectief op <span className='impact-title'>duurzaamheid</span></h2>
            <p className='case-intro'>	
            Samen met <a  href="https://www.asito.nl/rapportage/2023" target="_blank" rel="noopener noreferrer">Asito</a> en Cirkelen hebben we een duurzaamheidsstrategie ontwikkeld die voldoet aan de CSRD-richtlijnen en bijdraagt aan een betere leefomgeving. De strategie bevat een gefaseerde roadmap om onder andere plasticgebruik te verminderen. Daarnaast ondersteunen we in communicatie en gedragsverandering om medewerkers effectief bij het proces te betrekken en de strategie succesvol uit te voeren.
            </p>

        </aside>
    </article>
  );
}

export default CaseItemAs;